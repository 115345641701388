import Vue from 'vue'
import { KwixeoTable } from '@kwixeo/table-vue'
import i18n from '@/lang/i18n'
import axios from '@/axios'
import store from '@/store'
import router from '@/router'
import { formatToDisplay, formatDate, formatDatetime } from '@kwixeo/interface-vue'

Vue.use(KwixeoTable, {
  i18n,
  axios,
  router,
  store,
  localStorageKey: 'lists',
  importExportModelTranslationKey: 'importsExports.model',
  pageLength: 25,
  renders: {
    amount: (number: number) => formatToDisplay(number, false),
    currency: (number: number, type: KwixeoInterface.AnyValue, row: KwixeoInterface.AnyValue) => {
      return formatToDisplay(number, row.currency ?? null)
    },
    datetime: (datetime: string) => formatDatetime(datetime),
    date: (date: string) => formatDate(date)
  },
  currency: (row: KwixeoTable.Data) => {
    const currency = row?.currency?.symbol ?? store.getters['user/currency']?.symbol ?? null
    if (currency) {
      return ' ' + currency
    }
    return ''
  },
  phoneClickable: () => store.getters['user/teamConfigs'].phone_clickable ?? false
})
